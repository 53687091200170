// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-11011(2b4d2ae4b1)-C11/12/2024-11:37:30-B11/12/2024-11:43:08' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-11011(2b4d2ae4b1)-C11/12/2024-11:37:30-B11/12/2024-11:43:08",
  branch: "master",
  latestTag: "6.1",
  revCount: "11011",
  shortHash: "2b4d2ae4b1",
  longHash: "2b4d2ae4b146fc9c8cbe4a95bd10401e692a40f9",
  dateCommit: "11/12/2024-11:37:30",
  dateBuild: "11/12/2024-11:43:08",
  buildType: "Ansible",
  } ;
